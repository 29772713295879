import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import CardRoll from '../../components/CardRoll';
const CardIndexPage = props => (
  <Layout>
    <div
      className="full-width-image-container margin-top-0"
      style={{
        backgroundImage: `url('/img/fortune-index.jpg')`,
      }}
    >
      <h1
        className="has-text-weight-bold is-size-1"
        style={{
          boxShadow: '0.5rem 0 0 #f40, -0.5rem 0 0 #f40',
          backgroundColor: '#f40',
          color: 'white',
          padding: '1rem',
        }}
      >
        Latest Stories
    </h1>
    </div>
    <section className="section">
      <div className="container">
        <div className="content">
          <CardRoll data={props.data} />
        </div>
      </div>
      <Link className="button" to={'/cards'}>
        More Articles
      </Link>
    </section>
  </Layout>
);

export default CardIndexPage;

export const paginatedCardQuery = graphql`
  query CardRollQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "card-post" } } }
      limit: 6
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            base_keyword
            correct_title
            correct_meaning
            reverse_title
            reverse_meaning
            cardimage {
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 120, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
